export const G__Spinner = (
    el,
    { value, modifiers, expression },
    { effect, evaluate, evaluateLater }
) => {
    const type = el.getAttribute('type');
    const getStatus = evaluateLater(expression);

    let spinnerHtml = '';

    effect(() => {
        const status = evaluate(expression);

        const typeCheckmark = `
                <section class="g--spinner-checkmark">
                    <div class="circle-loader" :class="{'load-complete' : '${status}' == 'done'}">
                        <div class="checkmark draw" :class="{'checked' : '${status}' == 'done'}"></div>
                    </div>
                </section>
            `;

        if (type == 'checkmark') {
            spinnerHtml = typeCheckmark;
        }

        const html = `
                <div class="theme-spinner">
                    <div class="the-spinner">
                        ${spinnerHtml}
                    </div>
                </div>`;

        el.innerHTML = html;
    });
};
