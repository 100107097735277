/**
 * CardPerson
 * @namespace CardPerson
 * @memberof module:component
 * @desc Component module for the CardPerson
 * @see {@link module:component}
 * @see {@link module:component/card-person}
 *
 */

export const C__CardPerson = () => ({
    person: {},
    imageSrc: '',
    init() {
        this.fillModalWithData();
    },
    fillModalWithData() {
        const modal = document.getElementById('personModal');
        modal.addEventListener('show.bs.modal', (event) => {
            // Button that triggered the modal
            const button = event.relatedTarget;

            // Extract info from data-bs-* attributes
            this.person = JSON.parse(button.getAttribute('data-bs-json'));
            console.log(this.person);

            this.imageSrc = this.person.e__image.image.url;
        });
    }
});
