// import { B__Alpine } from './b--alpine/B__Alpine';
// import { B__Hero } from './b--hero/B__Hero';

import { B__BannerCases } from './b--banner-cases/B__BannerCases';
import { B__Hero } from './b--hero/B__Hero';

import { B__BannerTestimonials } from './b--banner-testimonials/B__BannerTestimonials';
import { B__CardsTitlesMixItUp } from './b--cards-titles-mixitup/B__CardsTitlesMixItUp';

export const Blocks = {
    directives: {},
    modules: {
        B__BannerCases,
        B__BannerTestimonials,
        B__CardsTitlesMixItUp,
        B__Hero
    }
};
