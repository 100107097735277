/**
 * Hero Which can display slides
 *
 * features
 * - image slide
 * - videos (youtube which hides beneneath the slide until activated)
 *
 * USES LIB YOUTBE PLAYER - https://www.npmjs.com/package/youtube-player#usage
 */

import YouTubePlayer from 'youtube-player';
import Glide from '@glidejs/glide';

export const B__Hero = () => ({
    expanded: false,
    player: null,
    glide: null,
    players: [],
    overlayIsActive: true,
    currentSlide: 0, // current player
    glideIndex: 0,
    v: null, // ACF repeater values from WP passed from template

    // init() {},

    async initializeWithTemplateData(v) {
        this.v = JSON.parse(v);
        console.log(this.v);

        const Module = this;

        /**
         * AlpineTemplateLogicIssueFix
         * @param {*} Glide
         * @param {*} Components
         * @param {*} Events
         * // since alpine creates a DOM <template x-for...> as an extra sibling to the li elements
         * // we need to remove it
         * @returns
         */
        const AlpineTemplateLogicIssueFix = function (
            Glide,
            Components,
            Events
        ) {
            return {
                modify(translate, current, slides) {
                    if (
                        Components.Html.slides.length > Module.v.slides.length
                    ) {
                        Components.Html.slides.shift();
                    }
                    return translate;
                }
            };
        };

        setTimeout(() => {
            const glide = new Glide('.glide');

            glide.on(['build.before'], function () {
                console.log('build.after', Module.v.slides);

                if (Module.v.slides) {
                    Module.v.slides.map((item, index) => {
                        if (item.type == 'video') {
                            Module.initializeYoutubeVideo(item);
                        }
                    });
                }
            });

            glide.on(['run'], function () {
                if (glide.index == Module.v.slides.length - 1) {
                    // console.log('DO NOT change SLIDE');
                }
            });

            glide.on(['mount.before', 'run'], function () {
                // Handler logic ...

                Module.glideIndex = glide.index;

                Module.setCurrentSlide(Module.getSlideObject(glide.index));

                Object.keys(Module.players).map((playerId, index) => {
                    Module.fadeOut(Module.players[playerId]);
                });

                Module.overlayIsActive = false;
            });

            // apply template fix (alpine + glide collision)
            this.glide = glide.mutate([AlpineTemplateLogicIssueFix]).mount();
        }, 100);
    },

    changeVolume(player, i) {
        // console.log(i);
        if (i) {
            player.api.getVolume().then((volume) => {
                player.api.setVolume(volume + 1);
                console.log(volume);
            });
        } else {
            console.log('-1');
            player.api.getVolume().then((volume) => {
                player.api.setVolume(volume - 1);
                console.log(volume);
            });
        }
    },

    getSlideObject(index) {
        return this.v.slides[index];
    },

    setCurrentSlide(slide) {
        this.currentSlide = slide;
    },

    findPlayerByYoutubeId(id) {
        return this.players.find((player) => player.id == id);
    },

    initializeYoutubeVideo(slide) {
        if (!slide.youtubeVideoId) return;

        const elemId = 'player_' + slide.youtubeVideoId;

        this.players.push({
            id: slide.youtubeVideoId,
            api: YouTubePlayer(elemId, {
                videoId: slide.youtubeVideoId,
                playerVars: {
                    controls: 1,
                    // playsinline: 1,
                    autoplay: 0,
                    rel: 0,
                    origin: window.location.origin,
                    enablejsapi: 1
                    // color: 'white'
                }
            })
        });

        let player = this.findPlayerByYoutubeId(slide.youtubeVideoId);

        // 'loadVideoById' is queued until the player is ready to receive API calls.
        // player.api.loadVideoById(slide.youtubeVideoId).then((res) => {
        //     player.api.stopVideo(); // prevents autoplay
        // });

        player.api.on('stateChange', (event) => {
            // event.data
            this.maybyOverlayIsActive(event.data);
        });
    },

    fadeOut() {
        let player = this.findPlayerByYoutubeId(
            this.currentSlide.youtubeVideoId
        );

        // repeat with the interval of 100 mili seconds
        let fadeOutTimerId = setInterval(() => this.changeVolume(player, 0), 2);

        // after 5 seconds stop
        setTimeout(() => {
            clearInterval(fadeOutTimerId);
            //  player.api.pauseVideo();
            // this.overlayIsActive = false;
        }, 400);
    },
    fadeIn() {
        let player = this.findPlayerByYoutubeId(
            this.currentSlide.youtubeVideoId
        );

        // repeat with the interval of 100 mili seconds
        let fadeInTimerId = setInterval(() => this.changeVolume(player, 1), 2);

        // after 5 seconds stop
        setTimeout(() => {
            clearInterval(fadeInTimerId);
        }, 400);
    },

    stopVideo() {
        let player = this.findPlayerByYoutubeId(
            this.currentSlide.youtubeVideoId
        );

        // this.fadeOut();
        // repeat with the interval of 100 mili seconds

        // after 5 seconds stop
        setTimeout(() => {
            player.api.pauseVideo();
            this.overlayIsActive = false;
        }, 400);

        //this.players[this.currentSlide.youtubeVideoId].pauseVideo();
    },
    playVideo() {
        let player = this.findPlayerByYoutubeId(
            this.currentSlide.youtubeVideoId
        );

        // player.api.playVideo().then((res) => {
        //     console.log(res);
        // });

        // this.fadeIn();

        // repeat with the interval of 100 mili seconds
        // let timerId = setInterval(() => Module.changeVolume(player, 1), 2);
        player.api.playVideo();
        // after 5 seconds stop
        // setTimeout(() => {
        //     // clearInterval(timerId);
        //     player.api.playVideo();
        // }, 100);

        this.overlayIsActive = true;
    },

    maybyOverlayIsActive(playerStatus) {
        if (playerStatus == -1) {
            // this.overlayIsActive = false; // unstarted
        } else if (playerStatus == 0) {
            this.overlayIsActive = false; // ended
            this.stopVideo();
        } else if (playerStatus == 1) {
            // this.fadeIn();
            this.overlayIsActive = true; // playing
        } else if (playerStatus == 2) {
            this.stopVideo(); // paused
        } else if (playerStatus == 3) {
            // this.overlayIsActive = true; // buffering
        } else if (playerStatus == 5) {
            // this.overlayIsActive = false; // video cued - When a video is cued and ready to play, the player will broadcast a video cued (5) event
        }
    }
});
