import Glide from '@glidejs/glide';

export const B__BannerCases = () => ({
    init: () => {
        var glide = new Glide('.b--banner-cases .glide', {
            type: 'slider',
            perView: 3,
            startAt: 1,
            peek: 100,
            focusAt: 'center',
            breakpoints: {
                1179: {
                    perView: 1
                }
            }
        });

        glide.mount();
    }
});
