import 'vite/modulepreload-polyfill';
// import 'mmenu-js/src/mmenu.scss';
// import 'mmenu-js/dist/mmenu.css';
import '../scss/theme.scss';
import 'mburger-webcomponent';
import axios from 'axios';
import Mmenu from 'mmenu-js';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';

import { Blocks } from '../../modules/block/alpine.load.js';
import { Components } from '../../modules/component/alpine.load.js';
import { Pages } from '../../modules/page/alpine.load.js';
import { General } from '../../modules/general/alpine.load.js';

const loadDirectivesAndModules = (modulesTypes) => {
    for (const [type, moduleGroup] of Object.entries(modulesTypes)) {
        for (const [alpineType, modules] of Object.entries(moduleGroup)) {
            if (Object.keys(modules).length !== 0) {
                registerAlpineModules(modules, alpineType);
            }
        }
    }
};

const registerAlpineModules = (modules, type) => {
    Object.keys(modules).forEach((module) => {
        let fn = modules[module];

        if (typeof fn === 'object') {
            let isNestedModules = fn;
            registerAlpineModules(isNestedModules, type);
            return;
        }

        if (type == 'modules') {
            Alpine.data(module, fn);
        }

        if (type == 'directives') {
            Alpine.directive(module, fn);
        }
    });
};

// Alpine.store('darkMode', {
//     on: Alpine.$persist(true).as('darkMode_on'),

//     toggle() {
//         this.on = !this.on;
//     }
// });

// Alpine.store('darkMode', {
//     on: Alpine.$persist(true).as('darkMode_on')
// });

// add this directive to replace parts {{}} of the html with the value of the expression
Alpine.directive('handlebars', (el, { expression }, { effect, evaluate }) => {
    // regex to match all double handlebars in a string

    const pattern = /{{\s*([^}]+)\s*}}/g;

    // store the original html
    const originalHtml = el.innerHTML;

    effect(() => {
        const modified = originalHtml.replaceAll(pattern, (match, property) => {
            return evaluate(property);
        });
        el.innerHTML = modified;
    });
});

// USAGE : <div x-spinner="spinnerStatus" :type="'checkmark'" class="theme-spinner" x-show="spinnerStatus !== 'idle'"></div>

//Alpine.data(...) provides a way to re-use x-data contexts within your application.
loadDirectivesAndModules({
    Blocks,
    Components,
    Pages,
    General
});

// window.Alpine = Alpine;
Alpine.plugin(persist);
Alpine.start();

/** mmenu */
document.addEventListener('DOMContentLoaded', () => {
    const menu = new Mmenu(
        '#mobile-menu',
        {
            // configuration
            offCanvas: {
                // use: false,
                position: 'right'
            },
            // options
            theme: 'dark' //   Possible values: "light", "light-contrast", "dark", "dark-contrast" "white", "white-contrast", "black" and "black-contrast".
        },
        {
            // configuration
            offCanvas: {
                page: {
                    selector: '#page'
                }
            }
        }
    );

    // const api = menu.API;

    // document.querySelector('#open-mmenu').addEventListener('click', () => {
    //     // const burger = document.querySelector('mm-burger');
    //     // burger.state = 'cross';
    //     api.open();
    // });

    // document.querySelector('#close-mmenu').addEventListener('click', () => {
    //     const burger = document.querySelector('mm-burger');
    //     burger.state = 'bars';
    //     api.close();
    // });
});
