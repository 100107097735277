import mixitup from 'mixitup';

export const B__CardsTitlesMixItUp = () => ({
    init: () => {
        var mixer = mixitup('.cards', {
            // controls: {
            //     toggleDefault: 'none'
            // }
        });
        // console.log('mixer', mixer);
    }
});
